import { useEffect, useRef, useState } from "react"
import s from "assets/scss/Slider.module.scss"

import cx from "classnames"
import Img from "./Img"

import keycoLogoIcon from "assets/img/keyco-logo-icon.png"
import company from "assets/img/company.jpg"
import consultancy from "assets/img/consultancy.jpg"
import corporate from "assets/img/corporate.jpg"
import collaborate from "assets/img/collaborate.jpg"
import connect from "assets/img/connect.jpg"
import ButtonCircle from "components/ButtonCircle"
import IconArrowSquare from "components/Icon/IconArrowSquare"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "hooks"
import { breakpointTypes } from "utils/types"

const Slider = () => {
  const maskRef = useRef<HTMLDivElement | null>(null)
  const titlesRef = useRef<any>(null)
  const { t } = useTranslation()

  const sliderItems = [
    {
      title: "company",
      img: company,
      mLeftTablet: 0,
      mLeftMobile: -40,
    },
    {
      title: "consultancy",
      img: consultancy,
      mLeftTablet: 140,
      mLeftMobile: 0,
    },
    { title: "corporate", img: corporate, mLeftTablet: -20, mLeftMobile: -60 },
    {
      title: "collaboration",
      img: collaborate,
      mLeftTablet: 90,
      mLeftMobile: -10,
    },
    { title: "connection", img: connect, mLeftTablet: -80, mLeftMobile: -80 },
    {
      title: "company",
      img: company,
      mLeftTablet: 0,
      mLeftMobile: -40,
    },
    {
      title: "consultancy",
      img: consultancy,
      mLeftTablet: 140,
      mLeftMobile: 0,
    },
    { title: "corporate", img: corporate, mLeftTablet: -20, mLeftMobile: -60 },
    {
      title: "collaboration",
      img: collaborate,
      mLeftTablet: 90,
      mLeftMobile: -10,
    },
    { title: "connection", img: connect, mLeftTablet: -80, mLeftMobile: -80 },
  ]

  const [currentItem, setCurrentItem] = useState<number>(0)
  const [count, setCount] = useState<number>(0)

  const timerRef = useRef<any>(null)
  const counterRef = useRef<any>(null)

  useEffect(() => {
    titlesRef.current = Array.from(document.querySelectorAll("[data-title]"))
  }, [])

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setCurrentItem((prev) => (prev + 1) % sliderItems.length)
    }, 4000)

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [currentItem])

  useEffect(() => {
    counterRef.current = setTimeout(() => {
      // console.log(count)

      setCount((prev) => prev + 1)
    }, 4000)

    return () => {
      clearTimeout(counterRef.current)
    }
  }, [count])

  // const handleSlide = (i: number) => {
  //   if (currentItem - 2 > i) {
  //     return 100
  //   } else if (currentItem > i) {
  //     return -100
  //   } else if (currentItem === i) {
  //     return 0
  //   }
  // }

  // function wrap(value: number, min: number, max: number) {
  //   var v = value - min
  //   var r = max - min

  //   return ((r + (v % r)) % r) + min
  // }

  const windowSize = useWindowSize()

  return (
    <>
      <div className={s.slider}>
        <div className={s.textW}>
          <div className={s.titleW}>
            <h1 className={cx(s.title, s.titleTop)}>
              The key of{" "}
              <div className={s.iconW}>
                <Img src={keycoLogoIcon} objectFit="contain"></Img>
              </div>
            </h1>
            <div
              className={s.mask}
              ref={maskRef}
              style={{
                marginLeft: `${
                  windowSize.width > breakpointTypes.tablet
                    ? "-80"
                    : windowSize.width > breakpointTypes.mobile
                    ? sliderItems[currentItem].mLeftTablet
                    : sliderItems[currentItem].mLeftMobile
                }px`,
              }}
            >
              <div
                className={s.circlee}
                style={{
                  transform: `rotate(${
                    -(360 / sliderItems.length) * count
                  }deg)`,
                }}
              >
                {sliderItems.map((item, i) => {
                  return (
                    <div
                      className={s.text}
                      key={i}
                      style={{
                        transform: `rotate(${
                          (360 / sliderItems.length) * i + 18
                        }deg)`,
                      }}
                    >
                      <p className={s.inner}> {item.title}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={s.descW}>
            <h2 className={s.desc}>{t("home.hero.small")}</h2>
          </div>
        </div>
        <div className={s.btnAndImgW}>
          <div className={s.imgW}>
            {sliderItems.map((item, i) => {
              return (
                <div
                  className={s.tWr}
                  style={{
                    visibility: currentItem === i ? "visible" : "hidden",
                    zIndex: currentItem === i ? "10" : "5",
                    transition: `1.3s all ease-in-out`,
                    transform: `translateY(${
                      currentItem === i ? 0 : currentItem > i ? -100 : 100
                    }%)`,
                  }}
                  key={i}
                >
                  <div
                    className={s.aWr}
                    style={{
                      visibility: currentItem === i ? "visible" : "hidden",
                      zIndex: currentItem === i ? "10" : "5",
                      transition: `1.3s all ease-in-out`,
                      transform: `scale(${currentItem === i ? 1 : 2})`,
                    }}
                  >
                    <Img src={item.img} objectFit="cover"></Img>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={s.btnW} data-parallax data-speed="0.2">
            <ButtonCircle
              icon={<IconArrowSquare fill="#fff" rotate={45} scale={2} />}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Slider
