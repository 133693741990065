import { useEffect, useLayoutEffect, useState } from "react"
import s from "./assets/scss/App.module.scss"

import { gsap, Power1 } from "gsap"
import { Route, Routes, useLocation } from "react-router"

import Home from "pages/Home"
import Contact from "pages/Contact"
import Projects from "pages/Projects"
import Sidebar from "components/Sidebar"
import Menu from "components/Menu"
import Blog from "pages/Blog"
import BlogDetail from "components/BlogDetail"
import DropdownLang from "components/DropdownLang"
import MagnetCursor from "components/MagnetCursor"
import { useWindowSize } from "./hooks"
import { WithSmooth } from "hocs/WithSmooth"
import { useTranslation } from "react-i18next"
import Preloader from "components/Preloader"
import { Helmet } from "react-helmet"

function App() {
  const windowSize = useWindowSize()
  const location = useLocation()
  const [displayLocation, setDisplayLocation] = useState(location)
  const { t } = useTranslation()

  useEffect(() => {
    const transitionElements = Array.from(
      document.querySelectorAll("[data-route-transition]")
    )

    if (location !== displayLocation) {
      gsap.to(transitionElements, {
        autoAlpha: 0,
        duration: 0.3,
        ease: Power1.easeInOut,
        onComplete: () => {
          window.scrollTo(0, 0)
          gsap.to(transitionElements, {
            autoAlpha: 1,
            duration: 0.4,
            delay: 1,
            ease: Power1.easeInOut,
          })
          setDisplayLocation(location)
        },
      })
    }
  }, [location, displayLocation, windowSize.width])

  const { i18n } = useTranslation()

  useLayoutEffect(() => {
    if (location.pathname === "/projects" || location.pathname === "/contact") {
      if (i18n.language === "TR") {
        i18n.changeLanguage("EN")
      }
    } else if (
      location.pathname === "/projeler" ||
      location.pathname === "/contact"
    ) {
      if (i18n.language === "EN") {
        i18n.changeLanguage("TR")
      }
    }
  }, [location.pathname, i18n])

  return (
    <div className={s.app}>
      {i18n.language === "EN" ? (
        <Helmet>
          <title>Keyco</title>
          <meta
            name="description"
            content="For investors and startup owners in early stages of firm establishment, Keyco. provides services such as; Performance enhancing financial reports, analysis tools, project & concept development, design management, coordination and salesmarketing consultancy."
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>Keyco</title>
          <meta
            name="description"
            content="Keyco. yeni markasını oluşturmak isteyen girişimcilere ve tamamlanmış markalara performans artırıcı fizibilite, değerleme, konsept oluşumu, tasarım yönetimi, proje geliştirme, koordinasyon ve satış-pazarlama danışmanlığı konularında hizmet vermektedir."
          />
        </Helmet>
      )}

      <Preloader></Preloader>
      <Sidebar></Sidebar>
      <Menu></Menu>
      <MagnetCursor></MagnetCursor>

      <WithSmooth location={displayLocation}>
        <div className={s.content} data-route-transition>
          <div className={s.languageW}>
            <DropdownLang></DropdownLang>
          </div>
          <Routes location={displayLocation}>
            <Route index element={<Home />}></Route>

            <Route path="/iletisim" element={<Contact />}></Route>
            <Route path="/contact" element={<Contact />}></Route>

            <Route path="/projeler" element={<Projects />}></Route>
            <Route path="/projects" element={<Projects />}></Route>

            <Route path="/blog" element={<Blog />}></Route>
            <Route path="/blog/:blogUrl" element={<BlogDetail />}></Route>
          </Routes>
        </div>
      </WithSmooth>
    </div>
  )
}

export default App
