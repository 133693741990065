import s from "assets/scss/Blog.module.scss"
import axios from "axios"
import { FormEvent, useEffect, useRef, useState } from "react"
import BlogCard from "../components/BlogCard"
import IconArrowSquare from "../components/Icon/IconArrowSquare"

import cx from "classnames"
import { useTranslation } from "react-i18next"
import { api } from "utils/types"
import Footer from "../components/Footer"

const Blog = () => {
  const keywordInputRef = useRef<HTMLInputElement>(null)
  const [keyword, setKeyword] = useState<string | null>(null)
  const { t } = useTranslation()

  // const blogs = [
  //   {
  //     title: "YENİ İÇERİKLERİ YAKALAMAK İÇİN",
  //     summary:
  //       "En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.",
  //     html: "<p> En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.</p>",
  //     id: "0",
  //     image: "",
  //   },
  //   {
  //     title: "YENİ İÇERİKLERİ YAKALAMAK İÇİN",
  //     summary:
  //       "En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.",
  //     html: "<p> En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.</p>",
  //     id: "1",
  //     image: "",
  //   },
  //   {
  //     title: "YENİ İÇERİKLERİ YAKALAMAK İÇİN",
  //     summary:
  //       "En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.",
  //     html: "<p> En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.</p>",
  //     id: "2",
  //     image: "",
  //   },
  //   {
  //     title: "YENİ İÇERİKLERİ YAKALAMAK İÇİN",
  //     summary:
  //       "En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.",
  //     html: "<p> En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.</p>",
  //     id: "3",
  //     image: "",
  //   },
  //   {
  //     title: "YENİ İÇERİKLERİ YAKALAMAK İÇİN",
  //     summary:
  //       "En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.",
  //     html: "<p> En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.</p>",
  //     id: "4",
  //     image: "",
  //   },
  // ]

  const searchFormRef = useRef<HTMLFormElement>(null)
  const [items, setItems] = useState<any>([])
  const { i18n } = useTranslation()

  useEffect(() => {
    axios
      .get(`${api}blog.php`, {
        params: {
          language: i18n.language,
        },
      })
      .then((res) => {
        const data = res.data
        setItems([...data])
        // console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [i18n.language])

  useEffect(() => {
    const handleSubmit = (e?: FormEvent) => {
      e?.preventDefault()

      axios
        .get(`${api}blog.php?keyword=${keyword}`, {
          params: {
            language: i18n.language,
          },
        })
        .then((res) => {
          const data = res.data
          setItems([...data])
          // console.log(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    if (keyword !== null) {
      handleSubmit()
    }
  }, [keyword])

  const handleCleanup = () => {
    setKeyword("")
    if (searchFormRef.current) searchFormRef.current.reset()
  }

  const handleFocus = (e: FormEvent | any) => {
    e.preventDefault()

    if (e.type === "focus" && e) {
      e.target.labels[0].style.opacity = "0.1"
    } else if (e.type === "blur" && e) {
      e.target.labels[0].style.opacity = "0.5"
    }
  }

  return (
    <>
      <main className={s.blog}>
        <section className={s.doubleTitleW}>
          <h2 className={s.title} data-parallax data-speed="0.2">
            Blog
          </h2>
          <h2 className={s.title} data-parallax data-speed="0.2">
            Blog
          </h2>
        </section>
        <section className={s.search}>
          <div className={s.searchbar}>
            <form
              className={s.form}
              ref={searchFormRef}
              onSubmit={(e) => e.preventDefault()}
            >
              <div className={s.inputW}>
                <label
                  className={cx(s.label, {
                    [s.hidden]: keyword,
                  })}
                  htmlFor="search"
                >
                  {t("blog.searchbarLabel")}
                </label>
                <input
                  ref={keywordInputRef}
                  className={s.input}
                  id="search"
                  type="text"
                  onChange={(e) => {
                    setKeyword(e.currentTarget.value)
                  }}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                />
                <button type="submit" className={cx(s.iconW)}>
                  <IconArrowSquare
                    fill="#3b3b3b"
                    rotate={-45}
                  ></IconArrowSquare>
                </button>
              </div>
              <div
                className={cx(s.cleanForm, {
                  [s.enabled]: keyword,
                })}
                onClick={handleCleanup}
              >
                <p className={s.text}>Temizle</p>
              </div>
            </form>
          </div>
        </section>
        <section className={s.blogsW}>
          {Array.isArray(items) &&
            items.map((blog: any, i) => {
              return <BlogCard key={i} blog={{ ...blog }}></BlogCard>
            })}
        </section>
      </main>
      <Footer></Footer>
    </>
  )
}

export default Blog
